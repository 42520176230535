<form autocomplete="nope" #form="ngForm">
    <ng-container *ngIf="office">
        <div class="responsive-grid">
            <div class="row">
                <ui-banner *ngIf="office.province === 'QC'" variant="info">
                    <p [innerHTML]="'company.office-form.beforeYouCanAddQcEmployees' | translate"></p>
                </ui-banner>
            </div>
            <div class="row">
                <mat-form-field>
                    <mat-label>{{ 'company.office-form.nameLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        name="name"
                        #name="ngModel"
                        [(ngModel)]="office.name"
                        placeholder="{{ 'company.office-form.canadianHeadquarters' | translate }}"
                    />
                    <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                    <mat-label>{{ 'company.office-form.countryLabel' | translate }}</mat-label>
                    <ui-country-autocomplete
                        placeholder="{{ 'company.office-form.countryPlaceholder' | translate }}"
                        name="countryName"
                        #country="ngModel"
                        [required]="true"
                        [(ngModel)]="office.country"
                        [KYB-Revalidate]="office.isPrimary"
                    ></ui-country-autocomplete>
                    <mat-error *ngIf="country.invalid">{{ country.errors | formErrors }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'company.office-form.provinceStateLabel' | translate }}</mat-label>
                    <ui-state-autocomplete
                        placeholder="{{ 'company.office-form.provinceStatePlaceholder' | translate }}"
                        name="provinceName"
                        #province="ngModel"
                        [required]="true"
                        [country]="office.country"
                        [(ngModel)]="office.province"
                        [KYB-Revalidate]="office.isPrimary"
                    >
                    </ui-state-autocomplete>
                    <mat-error *ngIf="province.invalid">{{ province.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <ng-container *ngIf="office.country === 'Canada'">
                    <mat-form-field>
                        <mat-label>{{ 'company.office-form.postalCodeLabel' | translate }}</mat-label>
                        <input
                            matInput
                            type="text"
                            required
                            name="postalCode"
                            #postal="ngModel"
                            [(ngModel)]="office.postalCode"
                            [textMask]="postalCodeMask"
                            placeholder="{{ 'company.office-form.postalCodePlaceholder' | translate }}"
                            [KYB-Revalidate]="office.isPrimary"
                        />
                        <mat-error *ngIf="postal.invalid">{{ postal.errors | formErrors }}</mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="office.country !== 'Canada'">
                    <mat-form-field>
                        <mat-label>{{ 'company.office-form.zipPostalCodeLabel' | translate }}</mat-label>
                        <input
                            matInput
                            type="text"
                            required
                            name="postalCode"
                            #postal="ngModel"
                            [(ngModel)]="office.postalCode"
                            placeholder="{{ 'company.office-form.zipPostalCodePlaceholder' | translate }}"
                            [KYB-Revalidate]="office.isPrimary"
                        />
                        <mat-error *ngIf="postal.invalid">{{ postal.errors | formErrors }}</mat-error>
                    </mat-form-field>
                </ng-container>
                <mat-form-field>
                    <mat-label>{{ 'company.office-form.cityLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        #city="ngModel"
                        name="city"
                        [(ngModel)]="office.city"
                        placeholder="{{ 'company.office-form.cityPlaceholder' | translate }}"
                        [KYB-Revalidate]="office.isPrimary"
                    />
                    <mat-error *ngIf="city.invalid">{{ city.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                    <mat-label>{{ 'company.office-form.addressLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        #address="ngModel"
                        name="addressLine1"
                        [(ngModel)]="office.addressLine1"
                        placeholder="{{ 'company.office-form.addressPlaceholder' | translate }}"
                        [KYB-Revalidate]="office.isPrimary"
                    />
                    <mat-error *ngIf="address.invalid">{{ address.errors | formErrors }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'company.office-form.suiteUnitLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        #address2="ngModel"
                        name="addressLine2"
                        [(ngModel)]="office.addressLine2"
                        placeholder="{{ 'company.office-form.suiteUnitPlaceholder' | translate }}"
                        [KYB-Revalidate]="office.isPrimary"
                    />
                    <mat-error *ngIf="address2.invalid">{{ address2.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-checkbox class="columns-12 align-left" name="isPrimary" [(ngModel)]="office.isPrimary">
                    {{ 'company.office-form.useAsBillingAddressLabel' | translate }}
                </mat-checkbox>
            </div>
        </div>
    </ng-container>
</form>
