<form #form="ngForm">
    <ng-container *ngIf="position">
        <div class="responsive-grid">
            <div class="row">
                <mat-form-field>
                    <mat-label>{{ 'company.position-form.nameLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        name="title"
                        required
                        #title="ngModel"
                        [(ngModel)]="position.title"
                        placeholder="{{ 'company.position-form.namePlaceholder' | translate }}"
                    />
                    <mat-error *ngIf="title.invalid">{{ title.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                    <mat-label>{{ 'company.position-form.descriptionLabel' | translate }}</mat-label>
                    <ui-rich-text
                        name="description"
                        #description="ngModel"
                        [(ngModel)]="position.description"
                        height="175px"
                    ></ui-rich-text>
                    <mat-error *ngIf="description.invalid">{{ description.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
</form>
