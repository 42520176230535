<form #form="ngForm" autocomplete="off">
    <ng-container *ngIf="bankAccount">
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'company.banking-info-form.bankInstituteNumber' | translate }}</mat-label>
                <input
                    KYB-Revalidate
                    matInput
                    type="text"
                    placeholder="{{ 'company.banking-info-form.bankInstituteNumberPlaceholder' | translate }}"
                    #bankInstitution="ngModel"
                    name="bankInstitution"
                    required
                    minlength="3"
                    pattern="[0-9]{3}"
                    [(ngModel)]="bankAccount.institutionNumber"
                />
                <mat-error *ngIf="bankInstitution.invalid">{{ bankInstitution.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'company.banking-info-form.bankTransitNumber' | translate }}</mat-label>
                <input
                    KYB-Revalidate
                    matInput
                    type="text"
                    placeholder="{{ 'company.banking-info-form.bankTransitNumberPlaceholder' | translate }}"
                    #bankBranch="ngModel"
                    name="bankBranch"
                    required
                    minlength="5"
                    pattern="[0-9]{5}"
                    [(ngModel)]="bankAccount.branchNumber"
                />

                <mat-error *ngIf="bankBranch.invalid">{{ bankBranch.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'company.banking-info-form.bankAccountNumber' | translate }}</mat-label>
                <input
                    KYB-Revalidate
                    matInput
                    type="text"
                    placeholder="{{ 'company.banking-info-form.bankAccountNumberPlaceholder' | translate }}"
                    #accountNumber="ngModel"
                    name="accountNumber"
                    required
                    minlength="5"
                    maxlength="12"
                    pattern="[0-9]+"
                    [(ngModel)]="bankAccount.accountNumber"
                />

                <mat-error *ngIf="accountNumber.invalid">{{ accountNumber.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
