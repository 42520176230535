import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KYBRevalidationWarningDirective } from '@app/directives/field-revalidation-warning/kyc-kyb-field-revalidation.directive';
import { AppCommonModule } from '@app/modules/common/common.module';
import { PlatformModule } from '@app/platform.module';
import { CompanyForms } from './components';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AppCommonModule,
        PlatformModule,
        KYBRevalidationWarningDirective,
    ],
    declarations: [...CompanyForms],
    exports: [...CompanyForms],
})
export class CompanyFormsModule {}
