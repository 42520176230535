import { Component, Input } from '@angular/core';

import { BankAccount } from '@models/company/bank-account.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-company-bank-account',
    templateUrl: './bank-account.template.html',
})
export class BankAccountForm extends BaseForm {
    @Input() bankAccount: BankAccount;
}
