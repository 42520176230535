import { Component, Input } from '@angular/core';
import { RemitterTypes } from '@app/constants/remitter-types';
import { TranslatableKey } from '@app/types/translatable.type';
import { BaseForm } from '@forms/base.form';
import { Company } from '@models/company/company.model';

interface RemitterTypeListItem {
    key: string;
    value: TranslatableKey;
}

@Component({
    selector: 'app-company-payroll-form[company][authorizationsDocumentsHaveBeenSubmitted]',
    templateUrl: './payroll.template.html',
    styleUrls: ['./payroll-form.style.scss'],
})
export class PayrollForm extends BaseForm {
    @Input() company!: Company;
    @Input() authorizationsDocumentsHaveBeenSubmitted!: boolean;
    @Input() lastPaidRecurringPayrollEndDate?: Date | null;

    remitterTypes = RemitterTypes;
    remitterTypesList: RemitterTypeListItem[] = Object.entries(RemitterTypes).map(([key, value]) => ({ key, value }));

    get required(): boolean {
        return Boolean(
            this.company.craBusinessNumber || this.company.craReferenceNumber || this.company.craProgramCode
        );
    }

    get onboarded(): boolean {
        return Boolean(this.authorizationsDocumentsHaveBeenSubmitted || this.lastPaidRecurringPayrollEndDate);
    }
}
