<!-- Modals -->
<app-common-bulk-select-employee-dialog
    #selectEmployees
    headerText="forms.tasks.assignEmployees"
    maxItemsToSelect="5"
    [displayBannerBasedOnMaxItems]="true"
    [hideSelectPage]="true"
    bannerContent="forms.tasks.reachedMaxNumberOfAssignees"
></app-common-bulk-select-employee-dialog>

<!-- Body -->
<form autocomplete="nope" #form="ngForm">
    <div class="app-grid">
        <!-- Name -->
        <mat-form-field class="columns-11 small-columns-12 margin-top-1">
            <mat-label>{{ 'forms.tasks.name' | appTranslate }}</mat-label>
            <input type="text" [(ngModel)]="task.name" name="name" #name="ngModel" required matInput />
            <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Description -->
        <mat-form-field class="columns-11 small-columns-12 margin-top-1">
            <mat-label>{{ 'forms.tasks.description' | appTranslate }}</mat-label>
            <ui-rich-text name="description" #description="ngModel" [(ngModel)]="task.description"></ui-rich-text>
            <mat-error *ngIf="description.invalid">{{ description.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- File attachment required -->
        <mat-checkbox class="columns-5 margin-top-1" name="isAttachmentRequired" [(ngModel)]="task.isAttachmentRequired"
            >{{ 'forms.tasks.fileAttachmentRequired' | appTranslate }}
        </mat-checkbox>

        <!-- Subtasks -->
        <ng-container *ngIf="!task.subtasks.length; else showSubTasks">
            <ui-button
                type="link"
                class="margin-bottom-3 margin-top-2 add-subtask-button"
                (click)="onAddSubTasks(); $event.preventDefault()"
            >
                <mat-icon class="margin-right-1" svgIcon="plus"></mat-icon>{{ 'forms.tasks.addSubtask' | appTranslate }}
            </ui-button>
        </ng-container>

        <ng-template #showSubTasks>
            <div class="app-grid margin-bottom-1 margin-top-2">
                <app-drag-and-drop-with-deletable-options
                    class="columns-12"
                    addButtonLabel="forms.tasks.addSubtask"
                    maxOptions="10"
                    optionAttributeName="description"
                    [emptyOption]="getEmptyTaskSubtask()"
                    [options]="subTasks"
                    (optionsChange)="setSubTasks($event)"
                    (optionRemoved)="onRemoveSubTask($event)"
                ></app-drag-and-drop-with-deletable-options>
            </div>
        </ng-template>

        <!-- To be completed by -->
        <div class="completed-by">
            <mat-label class="columns-2 margin-top-1">{{ 'forms.tasks.toBeCompletedBy' | appTranslate }} </mat-label>
            <ui-info
                class="columns-1 margin-left-1"
                text="{{ 'forms.tasks.thisWillDetermineWhetherTheTaskIsToBeCompletedByTheN' | appTranslate }}"
            ></ui-info>

            <mat-radio-group
                class="completed-by-radio vertical margin-top-1"
                name="completedByRadio"
                [(ngModel)]="toBeCompletedBy"
                (ngModelChange)="onAssigneeChange()"
                #completedByRadio="ngModel"
                required
            >
                <mat-radio-button [value]="'subject'">{{ employee.firstName }}</mat-radio-button>
                <mat-radio-button [value]="'manager'">{{
                    { key: 'forms.tasks.employeefirstNamesDirectManager', params: { firstName: employee.firstName } }
                        | appTranslate
                }}</mat-radio-button>
                <mat-radio-button [value]="'specific employee'">{{
                    'forms.tasks.specificEmployees' | appTranslate
                }}</mat-radio-button>
            </mat-radio-group>

            <!-- Specific employees -->
            <ng-container *ngIf="toBeCompletedBy === 'specific employee'">
                <!-- If there are assignees (up to max 5 only), then show Assignees Table -->
                <ng-container *ngIf="assignedEmployees.length; else noAssignees">
                    <div class="assignees-table">
                        <h3 class="ui header title">{{ 'forms.tasks.employeesSelected' | appTranslate }}</h3>

                        <div *ngFor="let assignedEmployee of assignedEmployees" class="row">
                            <div class="app-grid">
                                <!-- Employee avatar & name-->
                                <app-avatar class="columns-9" [employee]="assignedEmployee" [name]="true"> </app-avatar>

                                <!-- Remove button -->
                                <ui-button
                                    type="ghost"
                                    class="unassign-employee-button columns-3"
                                    (click)="unassignEmployee(assignedEmployee)"
                                    ><mat-icon class="margin-right-1" svgIcon="close"></mat-icon
                                    >{{ 'forms.tasks.remove' | appTranslate }}</ui-button
                                >
                            </div>
                        </div>
                    </div>

                    <ui-button type="outline" class="margin-top-1 margin-bottom-2" (click)="onAddOrRemoveEmployees()">{{
                        'forms.tasks.addOrRemoveEmployees' | appTranslate
                    }}</ui-button>
                </ng-container>

                <!-- If there are no assignees -->
                <ng-template #noAssignees>
                    <ui-button type="outline" class="margin-top-1 margin-bottom-2" (click)="onAddOrRemoveEmployees()">{{
                        'forms.tasks.addEmployees' | appTranslate
                    }}</ui-button>
                </ng-template>
            </ng-container>
        </div>

        <!-- Due date -->
        <mat-form-field class="columns-6 small-columns-12 margin-top-1">
            <mat-label>{{ 'forms.tasks.dueDate' | appTranslate }}</mat-label>
            <input
                matInput
                name="dueAt"
                placeholder="{{ 'forms.tasks.selectDate' | appTranslate }}"
                [matDatepicker]="picker"
                #dueAt="ngModel"
                [(ngModel)]="task.dueAt"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
</form>
