import { Component, Input } from '@angular/core';
import { AbilityService } from '@app/services/ability.service';
import { BaseForm } from '@forms/base.form';
import { Company } from '@models/company/company.model';

@Component({
    selector: 'app-company-form[company]',
    templateUrl: './company.template.html',
})
export class CompanyForm extends BaseForm {
    @Input() company!: Company;
    doingBusinessAsNameRequired: boolean;

    constructor(public abilityService: AbilityService) {
        super();

        this.doingBusinessAsNameRequired = this.abilityService.payroll();
    }
}
