import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    EmployeeCriteriaSelectionComponent,
    EmployeeSelectionCriteriaFields,
} from '@app/components/employee-criteria-selection/employee-criteria-selection.component';
import { EmployeeFilter } from '@app/models/employee/employee-filter.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { TitlecasePipe } from '@app/pipes';
import { AuthService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';

@Component({
    selector: 'deprecated-app-task-template-form',
    templateUrl: './deprecated-task-template.template.html',
    styleUrls: ['./deprecated-task-template.style.scss'],
})
export class DeprecatedTaskTemplateForm extends BaseForm implements OnInit {
    @Input() taskTemplate: TaskTemplate = new TaskTemplate();
    @ViewChild('employeeCriteriaSelection', { static: false })
    employeeCriteriaSelection: EmployeeCriteriaSelectionComponent;
    employeeFilter: EmployeeFilter = new EmployeeFilter();

    titleCasePipe = new TitlecasePipe();

    requiredForAll = true;
    assigneeType: string;

    constructor(private auth: AuthService) {
        super();
    }

    ngOnInit(): void {
        this.requiredForAll = !this.taskTemplate.employeeFilter?.filterCriteria?.length;

        if (this.taskTemplate.employeeFilter) {
            this.employeeFilter = this.taskTemplate.employeeFilter;
        }

        this.setInitialAssigneeType();
        this.setDefaultAssigneeType();
    }

    setDefaultAssigneeType(): void {
        if (this.assigneeType) {
            return;
        }
        this.assigneeType = 'subject';
        this.taskTemplate.assigneeType = 'subject';
    }

    setInitialAssigneeType(): void {
        if (this.taskTemplate.assigneeType) {
            this.assigneeType = this.taskTemplate.assigneeType;
            return;
        }
        this.assigneeType = this.taskTemplate.assigneeId ? TaskTemplate.assigeeTypeSpecificEmployee : null;
    }

    attachEmployeeFilters(): Promise<void> {
        if (this.requiredForAll) {
            this.taskTemplate.employeeFilterId = null;
            return;
        }
        this.employeeFilter.name = 'Onboarding Task: ' + this.taskTemplate.name;

        const toAttach = this.getValuesToAttach();
        const toDetach = this.getValuesToDetach();

        this.employeeFilter.attachEmployeeFilters(this.employeeFilter, toAttach);
        this.employeeFilter.detachEmployeeFilters(this.employeeFilter, toDetach);

        return this.employeeFilter
            .param('company', this.auth.company.id)
            .save()
            .then(() => {
                this.employeeCriteriaSelection.clearAttachDetachFilters();
                this.taskTemplate.employeeFilterId = this.employeeFilter.id;
            });
    }

    getValuesToAttach(): EmployeeSelectionCriteriaFields {
        return this.requiredForAll
            ? { departments: [], offices: [], employmentTypes: [], jobs: [] }
            : this.employeeCriteriaSelection.getValuesToAttach();
    }

    setAssigneeType(event: MatRadioChange): void {
        const assigneeType = event.value;

        if (assigneeType === TaskTemplate.assigeeTypeSubject || assigneeType === TaskTemplate.assigeeTypeManager) {
            this.taskTemplate.assignedTo = assigneeType;
            this.taskTemplate.assigneeId = null;
            return;
        }
        this.taskTemplate.isAttachmentRequired = false;
    }

    getValuesToDetach(): EmployeeSelectionCriteriaFields {
        return this.requiredForAll
            ? { departments: [], offices: [], employmentTypes: [], jobs: [] }
            : this.employeeCriteriaSelection.getValuesToDetach();
    }

    isAfterDueRelationDisabled(): boolean {
        return this.taskTemplate.taskType === 'offboarding' && this.isSubjectAssigneeType();
    }

    isSubjectAssigneeType(): boolean {
        return this.assigneeType === TaskTemplate.assigeeTypeSubject;
    }
}
