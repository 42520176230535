<!--Form-->
<form autocomplete="nope" #form="ngForm" class="app-grid">
    <ng-container *ngIf="assetCategory">
        <!-- Name -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.categoryNameLabel' | translate }}</mat-label>
            <input type="text" [(ngModel)]="assetCategory.name" name="name" #name="ngModel" required matInput />

            <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
        </mat-form-field>
    </ng-container>
</form>
