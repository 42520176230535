import { Component, Input } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Task } from '@app/models/tasks/task.model';
import { BaseForm } from '@forms/base.form';
@Component({
    selector: 'app-apply-tasks',
    templateUrl: './apply-tasks.template.html',
    styleUrls: ['./apply-tasks.style.scss'],
})
export class ApplyTasksForm extends BaseForm {
    @Input() tasks: Task[] = [];
    @Input() employee: Employee;

    @Input() generalTaskTemplates: TaskTemplate[] = [];

    @Input() autoMatchedTaskTemplates: TaskTemplate[] = [];
    @Input() otherTaskTemplates: TaskTemplate[] = [];

    isLoading = false;

    onboardingAutoMatchedTaskTemplates: TaskTemplate[] = [];
    offboardingAutoMatchedTaskTemplates: TaskTemplate[] = [];

    previouslySelectedGeneralTaskTemplates: TaskTemplate[] = [];
    previouslySelectedOnboardingAutoMatchedTaskTemplates: TaskTemplate[] = [];
    previouslySelectedOffboardingAutoMatchedTaskTemplates: TaskTemplate[] = [];
    previouslySelectedOtherTaskTemplates: TaskTemplate[] = [];

    selectedGeneralTaskTemplates: TaskTemplate[] = [];
    selectedOnboardingAutoMatchedTaskTemplates: TaskTemplate[] = [];
    selectedOffboardingAutoMatchedTaskTemplates: TaskTemplate[] = [];
    selectedOtherTaskTemplates: TaskTemplate[] = [];

    selectedTaskTemplates: TaskTemplate[] = [];

    constructor() {
        super();
    }

    beforeShow(): void {
        // filter other tasks based on onboarding & offboarding task templates
        this.otherTaskTemplates = this.otherTaskTemplates.filter(
            (otherTaskTemplate) =>
                otherTaskTemplate.taskType === 'onboarding' || otherTaskTemplate.taskType === 'offboarding'
        );

        // set previously selected task templates
        this.setpreviouslySelectedGeneralTaskTemplates();
        this.setpreviouslySelectedAutoMatchedTaskTemplates();
        this.setPreviouslySelectedOtherTaskTemplates();

        // reset selected task templates
        this.selectedOnboardingAutoMatchedTaskTemplates = [];
        this.selectedOffboardingAutoMatchedTaskTemplates = [];
        this.selectedOtherTaskTemplates = [];

        this.selectedGeneralTaskTemplates = [];
    }

    getSelectedTaskTemplates(): TaskTemplate[] {
        this.selectedTaskTemplates = [
            ...this.selectedGeneralTaskTemplates,
            ...this.selectedOnboardingAutoMatchedTaskTemplates,
            ...this.selectedOffboardingAutoMatchedTaskTemplates,
            ...this.selectedOtherTaskTemplates,
        ];
        return this.selectedTaskTemplates;
    }

    getCountOfGeneralTasks(): number {
        return this.selectedGeneralTaskTemplates.length + this.previouslySelectedGeneralTaskTemplates.length;
    }

    getCountOfOnboardingRelevantTasks(): number {
        return (
            this.selectedOnboardingAutoMatchedTaskTemplates.length +
            this.previouslySelectedOnboardingAutoMatchedTaskTemplates.length
        );
    }

    getCountOfOffboardingRelevantTasks(): number {
        return (
            this.selectedOffboardingAutoMatchedTaskTemplates.length +
            this.previouslySelectedOffboardingAutoMatchedTaskTemplates.length
        );
    }

    getCountOfOtherTasks(): number {
        return this.selectedOtherTaskTemplates.length + this.previouslySelectedOtherTaskTemplates.length;
    }

    private setpreviouslySelectedGeneralTaskTemplates(): void {
        const generalAutoMatchedTaskTemplates = this.autoMatchedTaskTemplates.filter(
            (taskTemplate) => taskTemplate.taskType === 'general'
        );

        this.previouslySelectedGeneralTaskTemplates = generalAutoMatchedTaskTemplates.filter(
            (autoMatchedTaskTemplate) => {
                return this.tasks.some((task) => task.taskTemplateId === autoMatchedTaskTemplate.id);
            }
        );
    }

    private setpreviouslySelectedAutoMatchedTaskTemplates(): void {
        // filter auto matched task templates based on onboarding and offboarding task templates
        this.onboardingAutoMatchedTaskTemplates = this.autoMatchedTaskTemplates.filter(
            (taskTemplate) => taskTemplate.taskType === 'onboarding'
        );
        this.offboardingAutoMatchedTaskTemplates = this.autoMatchedTaskTemplates.filter(
            (taskTemplate) => taskTemplate.taskType === 'offboarding'
        );

        this.previouslySelectedOnboardingAutoMatchedTaskTemplates = this.onboardingAutoMatchedTaskTemplates.filter(
            (onboardingAutoMatchedTaskTemplate) => {
                return this.tasks.some((task) => task.taskTemplateId === onboardingAutoMatchedTaskTemplate.id);
            }
        );

        this.previouslySelectedOffboardingAutoMatchedTaskTemplates = this.offboardingAutoMatchedTaskTemplates.filter(
            (offboardingAutoMatchedTaskTemplate) => {
                return this.tasks.some((task) => task.taskTemplateId === offboardingAutoMatchedTaskTemplate.id);
            }
        );
    }

    private setPreviouslySelectedOtherTaskTemplates(): void {
        this.previouslySelectedOtherTaskTemplates = this.otherTaskTemplates.filter((otherTaskTemplate) => {
            return this.tasks.some((task) => task.taskTemplateId === otherTaskTemplate.id);
        });
    }
}
