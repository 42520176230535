<!--Modals-->
<app-deprecated-modal-form
    #createAssetCategoryModal
    headerText="{{ 'forms.assets.addNewCategory' | translate }}"
    [form]="assetCategoryForm"
    (save)="createAssetCategory(assetCategoryForm)"
    (cancel)="onCancel()"
>
    <app-asset-category-form #assetCategoryForm></app-asset-category-form>
</app-deprecated-modal-form>

<!--Form-->
<form autocomplete="nope" #form="ngForm" class="app-grid" [class.loading]="isLoading">
    <ng-container *ngIf="asset">
        <!-- Name -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.assetNameLabel' | translate }}</mat-label>
            <input
                type="text"
                [(ngModel)]="asset.name"
                name="assetName"
                placeholder="{{ 'forms.assets.egAssetName' | translate }}"
                #assetName="ngModel"
                required
                matInput
            />

            <mat-error *ngIf="assetName.invalid">{{ assetName.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Category -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.assets.assetCategoryLabel' | translate }}</mat-label>
            <mat-select
                placeholder="{{ 'forms.assets.selectCategory' | translate }}"
                name="categorySelect"
                #category="ngModel"
                [compareWith]="compareWith"
                [(ngModel)]="asset.category"
            >
                <mat-option class="create" (click)="createAssetCategoryModal.show()"
                    ><span class="add-option">+ {{ 'forms.assets.addNewCategory' | translate }}</span></mat-option
                >
                <mat-option *ngFor="let category of allAssetCategories" [value]="category">
                    {{ category.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Serial Number -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.serialNumberLabel' | translate }}</mat-label>
            <input type="text" [(ngModel)]="asset.serialNumber" name="serialNumber" #serialNumber="ngModel" matInput />

            <mat-error *ngIf="serialNumber.invalid">{{ serialNumber.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Notes -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.notesLabel' | translate }}</mat-label>
            <input type="text" [(ngModel)]="asset.notes" name="notes" #notes="ngModel" matInput />

            <mat-error *ngIf="notes.invalid">{{ notes.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Cost -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.costLabel' | translate }}</mat-label>
            <input type="text" [(ngModel)]="asset.cost" name="cost" #cost="ngModel" matInput />

            <mat-error *ngIf="cost.invalid">{{ cost.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Assign To -->
        <mat-form-field *ngIf="newAsset" class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.assignedToLabel' | translate }}</mat-label>
            <ui-employee-autocomplete
                name="assignedTo"
                [(ngModel)]="asset.assignment.employee"
                [allowNone]="true"
                [placeholder]="'forms.assets.unassigned' | translate"
                #assignedTo="ngModel"
                [required]="false"
            ></ui-employee-autocomplete>
            <mat-error *ngIf="assignedTo.invalid">{{ assignedTo.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Date Assigned -->
        <mat-form-field *ngIf="newAsset && asset.assignment.employee" class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.dateAssignedLabel' | translate }}</mat-label>
            <input
                matInput
                name="dateAssigned"
                [matDatepicker]="picker"
                #dateAssigned="ngModel"
                required
                [(ngModel)]="asset.assignment.assignedOn"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </ng-container>
</form>
