import { Component, Input } from '@angular/core';
import { makePostalCodeMask } from '@app/functions';
import { AbilityService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { Office } from '@models/company/office.model';

@Component({
    selector: 'app-office-form',
    templateUrl: './office.template.html',
    styleUrls: ['./office-form.style.scss'],
})
export class OfficeForm extends BaseForm {
    @Input() office: Office = new Office();
    postalCodeMask = makePostalCodeMask();
    payrollEnabled = false;

    constructor(private abilities: AbilityService) {
        super();
        this.payrollEnabled = this.abilities.payroll();
    }
}
