import { Component, Input, OnInit } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { Asset } from '@app/models/company/asset.model';
import { AssetCategory } from '@app/models/company/asset-category.model';
import { AssetCategoryForm } from '../asset-category/asset-category.form';
import { NotifyService } from '@app/services';
import { AssetAssignment } from '@app/models/company/asset-assignment.model';

@Component({
    selector: 'app-asset-form',
    templateUrl: './asset.template.html',
    styleUrls: ['./asset.style.scss'],
})
export class AssetForm extends BaseForm implements OnInit {
    @Input() asset: Asset = new Asset();

    isLoading = true;

    assetCategory: AssetCategory = new AssetCategory();
    allAssetCategories: AssetCategory[] = [];

    newAsset = false;

    constructor(private notify: NotifyService) {
        super();
    }

    ngOnInit(): void {
        this.getAssetCategories();
        if (!this.asset.name) {
            this.newAsset = true;
        }

        if (!this.asset.assignment) {
            this.asset.assignment = new AssetAssignment();
        }
    }

    async createAssetCategory(assetCategoryForm: AssetCategoryForm): Promise<void> {
        assetCategoryForm.submit();

        if (assetCategoryForm.form.invalid) {
            return;
        }

        this.isLoading = true;
        this.assetCategory = assetCategoryForm.assetCategory;

        this.assetCategory.save().then((category: AssetCategory) => {
            this.asset.category = category;
            this.getAssetCategories();

            this.notify.success('Asset Category Saved');
            this.isLoading = false;
        });
    }

    onCancel(): void {}

    compareWith(assetCategory: AssetCategory, selectedAssetCategory: AssetCategory): boolean {
        return assetCategory && selectedAssetCategory && assetCategory.id === selectedAssetCategory.id;
    }

    private async getAssetCategories(): Promise<void> {
        const [assetCategories] = await AssetCategory.all();
        this.allAssetCategories = assetCategories;
        this.isLoading = false;
    }
}
