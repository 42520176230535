import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { AssetAssignment } from '@app/models/company/asset-assignment.model';
import { Employee } from '@app/models/employee/employee.model';

@Component({
    selector: 'app-reassign-asset',
    templateUrl: './reassign-asset.template.html',
    styleUrls: ['./reassign-asset.style.scss'],
})
export class ReassignAssetForm extends BaseForm {
    @Input() assetAssignment: AssetAssignment = new AssetAssignment();
    @Input() assignee: Employee;
    @Input() assetReassignment: AssetAssignment;
}
