import { Component, Input } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Task } from '@app/models/tasks/task.model';
import { BaseForm } from '@forms/base.form';
@Component({
    selector: 'app-deprecated-apply-tasks',
    templateUrl: './deprecated-apply-tasks.template.html',
    styleUrls: ['./deprecated-apply-tasks.style.scss'],
})
export class DeprecatedApplyTasksForm extends BaseForm {
    @Input() tasks: Task[] = [];
    @Input() employee: Employee;
    @Input() autoMatchedTaskTemplates: TaskTemplate[] = [];
    @Input() otherTaskTemplates: TaskTemplate[] = [];
    @Input() showEmptyState = false;

    isLoading = true;

    previouslySelectedAutoMatchedTaskTemplates: TaskTemplate[] = [];
    previouslySelectedOtherTaskTemplates: TaskTemplate[] = [];

    selectedAutoMatchedTaskTemplates: TaskTemplate[] = [];
    selectedOtherTaskTemplates: TaskTemplate[] = [];

    selectedTaskTemplates: TaskTemplate[] = [];

    constructor() {
        super();
    }

    beforeShow(): void {
        this.getpreviouslySelectedAutoMatchedTaskTemplates();
        this.getPreviouslySelectedOtherTaskTemplates();

        this.selectedOtherTaskTemplates = [];

        // initially, all tasks templates should be selected by default.
        if (!this.previouslySelectedAutoMatchedTaskTemplates.length) {
            this.selectedAutoMatchedTaskTemplates = [...this.autoMatchedTaskTemplates];
            return;
        }

        this.selectedAutoMatchedTaskTemplates = [];
    }

    getSelectedTaskTemplates(): TaskTemplate[] {
        this.selectedTaskTemplates = [...this.selectedAutoMatchedTaskTemplates, ...this.selectedOtherTaskTemplates];
        return this.selectedTaskTemplates;
    }

    getCountOfRelevantTasks(): number {
        return this.selectedAutoMatchedTaskTemplates.length + this.previouslySelectedAutoMatchedTaskTemplates.length;
    }

    getCountOfOtherTasks(): number {
        return this.selectedOtherTaskTemplates.length + this.previouslySelectedOtherTaskTemplates.length;
    }

    private getpreviouslySelectedAutoMatchedTaskTemplates(): void {
        this.previouslySelectedAutoMatchedTaskTemplates = this.autoMatchedTaskTemplates.filter(
            (autoMatchedTaskTemplate) => {
                return this.tasks.some((task) => task.taskTemplateId === autoMatchedTaskTemplate.id);
            }
        );
    }

    private getPreviouslySelectedOtherTaskTemplates(): void {
        this.previouslySelectedOtherTaskTemplates = this.otherTaskTemplates.filter((otherTaskTemplate) => {
            return this.tasks.some((task) => task.taskTemplateId === otherTaskTemplate.id);
        });
    }
}
