import { Component, Input } from '@angular/core';
import { Job as Position } from '@models/company/job.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-position-form',
    templateUrl: './position.template.html',
})
export class PositionForm extends BaseForm {
    @Input() position: Position = new Position();
}
