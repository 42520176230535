import { Directive, Input } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { PersonaScope } from '@app/modules/payroll/components/kyc-kyb/types';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';
import { KYCKYBService } from '@app/services/kyc-kyb/kyc-kyb.service';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BaseRevalidationWarningDirective, FIELD_REVALIDATION_DISPLAY_CONDITION, FIELD_REVALIDATION_MESSAGE } from '.';

/**
 * Creates the display condition function for both directives.
 * They're essentially identical other than the observable they subscribe to for either Company or Account status for KYB and KYC respectively
 */
const createDisplayCondition =
    (scope: PersonaScope) =>
    (featureService: FeatureService, knowYourService: KYCKYBService, authService: AuthService): Observable<boolean> =>
        from(featureService.has(FeatureFlag.KYC_KYBRevalidation)).pipe(
            switchMap((isFlagEnabled) => {
                if (!isFlagEnabled) {
                    return of(false);
                }

                if (!authService.hasPayrollAccess) {
                    return of(false);
                }

                // Only show the warning if they've already completed KYC/KYB since they will need to restart the flow
                return knowYourService[scope === 'KYC' ? 'accountStatus' : 'companyStatus'].pipe(
                    map((status) => status !== 'not_complete'),
                    // Their status is not going to change while on any of the pages where they can change a field value with this warning
                    // Therefore, we just take the first status and immediately complete the observable to close the subscription
                    take(1)
                );
            })
        );

/**
 * Warns users they will need to go through KYC again if they edit the field this directive is attached to
 */
@Directive({
    selector: '[KYC-Revalidate]',
    standalone: true,
    providers: [
        { provide: FIELD_REVALIDATION_MESSAGE, useValue: 'payroll.kyc-kyb.revalidation-warning.KYC' },
        {
            provide: FIELD_REVALIDATION_DISPLAY_CONDITION,
            useFactory: createDisplayCondition('KYC'),
            deps: [FeatureService, KYCKYBService, AuthService],
        },
    ],
})
export class KYCRevalidationWarningDirective extends BaseRevalidationWarningDirective {}

/**
 * Warns users they will need to go through KYB again if they edit the field this directive is attached to
 */
@Directive({
    selector: '[KYB-Revalidate]',
    standalone: true,
    providers: [
        { provide: FIELD_REVALIDATION_MESSAGE, useValue: 'payroll.kyc-kyb.revalidation-warning.KYB' },
        {
            provide: FIELD_REVALIDATION_DISPLAY_CONDITION,
            useFactory: createDisplayCondition('KYB'),
            deps: [FeatureService, KYCKYBService, AuthService],
        },
    ],
})
export class KYBRevalidationWarningDirective extends BaseRevalidationWarningDirective {
    @Input('KYB-Revalidate') set isEnabled(isEnabled: boolean | string) {
        // Allows for disabling the directive conditionally (ie. only using it on the office form for primary offices)
        if (isEnabled === false) {
            this.unsubscribeFromFieldChanges();
        }
        if (isEnabled === true) {
            this.subscribeToFieldChanges();
        }
    }
}
