<div class="tasks-content">
    <!--Apply Tasks-->
    <ng-container [class.loading]="isLoading">
        <mat-accordion>
            <!--Relevant Tasks-->
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'forms.applicant-tracker.onboarding.relevantTasks' | appTranslate }}
                        <!--count-->
                        <ng-container>
                            <span *ngIf="getCountOfRelevantTasks() > 0" class="counter">
                                {{ getCountOfRelevantTasks() }}
                            </span>
                        </ng-container>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex-container content">
                    <ng-container
                        *ngIf="
                            selectedAutoMatchedTaskTemplates.length ||
                                previouslySelectedAutoMatchedTaskTemplates.length;
                            else relevantTasksEmptyState
                        "
                    >
                        <app-group-checkboxes
                            name="selectedAutoMatchedTaskTemplates"
                            [entities]="autoMatchedTaskTemplates"
                            [previousEntities]="previouslySelectedAutoMatchedTaskTemplates"
                            [disablePreviousSelection]="true"
                            [(ngModel)]="selectedAutoMatchedTaskTemplates"
                        ></app-group-checkboxes>
                    </ng-container>

                    <ng-template #relevantTasksEmptyState>
                        <span *ngIf="showEmptyState">
                            <p class="margin-top-1 margin-bottom-1">
                                {{
                                    'forms.applicant-tracker.onboarding.thereAreNoRelevantTasksForThisEmployee'
                                        | appTranslate
                                }}
                            </p></span
                        >
                    </ng-template>
                </div>
            </mat-expansion-panel>

            <!--Not Applicable Tasks-->
            <mat-expansion-panel class="last-segment-border">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'forms.applicant-tracker.onboarding.otherTasks' | appTranslate }}
                        <!--count-->
                        <ng-container>
                            <span *ngIf="getCountOfOtherTasks() > 0" class="counter">
                                {{ getCountOfOtherTasks() }}
                            </span>
                        </ng-container>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex-container content">
                    <ng-container *ngIf="otherTaskTemplates.length; else otherTasksEmptyState">
                        <app-group-checkboxes
                            name="selectedOtherTaskTemplates"
                            [entities]="otherTaskTemplates"
                            [previousEntities]="previouslySelectedOtherTaskTemplates"
                            [disablePreviousSelection]="true"
                            [(ngModel)]="selectedOtherTaskTemplates"
                        ></app-group-checkboxes>
                    </ng-container>

                    <ng-template #otherTasksEmptyState>
                        <span *ngIf="showEmptyState">
                            <p class="margin-top-1 margin-bottom-1">
                                {{
                                    'forms.applicant-tracker.onboarding.thereAreNoOtherTasksForThisEmployee'
                                        | appTranslate
                                }}
                            </p></span
                        >
                    </ng-template>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</div>
