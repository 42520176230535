<form autocomplete="nope" #form="ngForm" class="app-grid">
    <ng-container *ngIf="taskTemplate">
        <mat-form-field class="columns-11 small-columns-12 template-fields">
            <mat-label>{{ 'forms.tasks-template.name' | appTranslate }}</mat-label>
            <input type="text" [(ngModel)]="taskTemplate.name" name="name" #name="ngModel" required matInput />

            <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-11 small-columns-12 template-fields">
            <mat-label>{{ 'forms.tasks-template.description' | appTranslate }}</mat-label>
            <ui-rich-text
                name="description"
                #description="ngModel"
                [(ngModel)]="taskTemplate.description"
            ></ui-rich-text>
            <mat-error *ngIf="description.invalid">{{ description.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- radio buttons for assigned to field-->
        <mat-label class="assignee-type-label template-fields"
            >{{ 'forms.tasks-template.assignedTo' | appTranslate }}
        </mat-label>
        <mat-radio-group
            class="assignee-type-radio"
            name="assigneeTypeRadio"
            [(ngModel)]="assigneeType"
            (change)="setAssigneeType($event)"
            #assigneeTypeRadio="ngModel"
            required
        >
            <mat-radio-button *ngIf="taskTemplate.taskType === 'onboarding'" [value]="'subject'">{{
                'forms.tasks-template.newHire' | appTranslate
            }}</mat-radio-button>
            <mat-radio-button *ngIf="taskTemplate.taskType === 'offboarding'" [value]="'subject'">{{
                'forms.tasks-template.offboardingEmployee' | appTranslate
            }}</mat-radio-button>
            <mat-radio-button [value]="'manager'">{{ 'forms.tasks-template.manager' | appTranslate }}</mat-radio-button>
            <mat-radio-button [value]="'specific employee'">{{
                'forms.tasks-template.specificEmployees' | appTranslate
            }}</mat-radio-button>
        </mat-radio-group>

        <mat-form-field
            *ngIf="assigneeType === 'specific employee'"
            class="columns-11 small-columns-12 assignee-autocomplete"
        >
            <ui-employee-autocomplete
                name="assignedTo"
                [(ngModel)]="taskTemplate.assignedTo"
                placeholder="Unassigned"
                #assignedTo="ngModel"
                [required]="true"
            ></ui-employee-autocomplete>
            <mat-error *ngIf="assignedTo.invalid">{{ assignedTo.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-checkbox
            class="template-fields"
            *ngIf="isSubjectAssigneeType()"
            name="isAttachmentRequired"
            [(ngModel)]="taskTemplate.isAttachmentRequired"
            >{{ 'forms.tasks-template.fileAttachmentRequired' | appTranslate }}
        </mat-checkbox>

        <mat-form-field class="columns-11 small-columns-12 template-fields">
            <mat-label appRemoveAriaOwns>{{ 'forms.tasks-template.dueDate' | appTranslate }}</mat-label>
            <mat-select name="dueDateType" [(ngModel)]="taskTemplate.dueDateType">
                <mat-option
                    *ngFor="let dueDateType of taskTemplate.getDueDateTypes(taskTemplate.taskType)"
                    [value]="dueDateType"
                >
                    {{ dueDateType | titlecase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="taskTemplate.isCustomDueDateType()">
            <div class="flex-container content">
                <mat-form-field class="due-offset">
                    <input
                        type="number"
                        min="0"
                        [(ngModel)]="taskTemplate.dueOffset"
                        name="dueOffset"
                        #name="ngModel"
                        required
                        matInput
                    />
                </mat-form-field>

                <mat-form-field class="due-type">
                    <mat-select
                        name="dueType"
                        [(ngModel)]="taskTemplate.dueType"
                        placeholder="{{ 'forms.tasks-template.days' | appTranslate }}"
                        required
                    >
                        <mat-option value="day">{{ 'forms.tasks-template.days' | appTranslate }}</mat-option>
                        <mat-option value="month">{{ 'forms.tasks-template.months' | appTranslate }}</mat-option>
                        <mat-option value="year">{{ 'forms.tasks-template.years' | appTranslate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="!isAfterDueRelationDisabled()">
                    <mat-form-field class="due-relation">
                        <mat-select
                            name="dueRelation"
                            [(ngModel)]="taskTemplate.dueRelation"
                            placeholder="{{ 'forms.tasks-template.before' | appTranslate }}"
                            required
                        >
                            <mat-option
                                *ngFor="let dueRelation of taskTemplate.getDueRelations()"
                                [value]="dueRelation"
                            >
                                {{ dueRelation | titlecase }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="isAfterDueRelationDisabled()">
                    <mat-label>{{ 'forms.tasks-template.before' | appTranslate }} </mat-label>
                </ng-container>

                <mat-label *ngIf="taskTemplate.taskType === 'onboarding'">{{
                    'forms.tasks-template.hireDate' | appTranslate
                }}</mat-label>
                <mat-label *ngIf="taskTemplate.taskType === 'offboarding'">{{
                    'forms.tasks-template.lastDayOfWork' | appTranslate
                }}</mat-label>
            </div>
        </ng-container>

        <div class="requiredFor template-fields">
            <mat-label>{{ 'forms.tasks-template.requiredFor' | appTranslate }}</mat-label>
            <mat-radio-group
                class="required-for-radio"
                aria-label="Required For"
                name="requiredFor"
                [(ngModel)]="requiredForAll"
                #requiredFor="ngModel"
                required
            >
                <mat-radio-button [value]="true">{{
                    'forms.tasks-template.allEmployees' | appTranslate
                }}</mat-radio-button>

                <mat-radio-button [value]="false">{{
                    'forms.tasks-template.specificEmployees' | appTranslate
                }}</mat-radio-button>
            </mat-radio-group>

            <mat-error *ngIf="requiredFor.invalid">{{ requiredFor.errors | formErrors }}</mat-error>

            <div *ngIf="requiredForAll === false" class="filter-employees">
                <app-employee-criteria-selection
                    #employeeCriteriaSelection
                    [employeeFilter]="taskTemplate.employeeFilter"
                ></app-employee-criteria-selection>
            </div>
        </div>
    </ng-container>
</form>
