<div class="tasks-content">
    <!--Apply Tasks-->
    <ng-container [class.loading]="isLoading">
        <mat-accordion>
            <!-- General Tasks -->
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        General Tasks
                        <!--count-->
                        <ng-container>
                            <span *ngIf="getCountOfGeneralTasks() > 0" class="counter">
                                {{ getCountOfGeneralTasks() }}
                            </span>
                        </ng-container>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex-container content">
                    <app-group-checkboxes
                        name="selectedGeneralTaskTemplates"
                        [entities]="generalTaskTemplates"
                        [previousEntities]="previouslySelectedGeneralTaskTemplates"
                        [disablePreviousSelection]="true"
                        [(ngModel)]="selectedGeneralTaskTemplates"
                    ></app-group-checkboxes>
                </div>
            </mat-expansion-panel>

            <!-- Relevant Onboarding Tasks -->
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Relevant Onboarding Tasks
                        <!--count-->
                        <ng-container>
                            <span *ngIf="getCountOfOnboardingRelevantTasks() > 0" class="counter">
                                {{ getCountOfOnboardingRelevantTasks() }}
                            </span>
                        </ng-container>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex-container content">
                    <app-group-checkboxes
                        name="selectedOnboardingTaskTemplates"
                        [entities]="onboardingAutoMatchedTaskTemplates"
                        [previousEntities]="previouslySelectedOnboardingAutoMatchedTaskTemplates"
                        [disablePreviousSelection]="true"
                        [(ngModel)]="selectedOnboardingAutoMatchedTaskTemplates"
                    ></app-group-checkboxes>
                </div>
            </mat-expansion-panel>

            <!-- Relevant Offboarding Tasks -->
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Relevant Offboarding Tasks
                        <!--count-->
                        <ng-container>
                            <span *ngIf="getCountOfOffboardingRelevantTasks() > 0" class="counter">
                                {{ getCountOfOffboardingRelevantTasks() }}
                            </span>
                        </ng-container>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex-container content">
                    <app-group-checkboxes
                        name="selectedOffboardingTaskTemplates"
                        [entities]="offboardingAutoMatchedTaskTemplates"
                        [previousEntities]="previouslySelectedOffboardingAutoMatchedTaskTemplates"
                        [disablePreviousSelection]="true"
                        [(ngModel)]="selectedOffboardingAutoMatchedTaskTemplates"
                    ></app-group-checkboxes>
                </div>
            </mat-expansion-panel>

            <!-- Other Offboarding/Onboarding Tasks -->
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Other Offboarding/Onboarding Tasks
                        <!--count-->
                        <ng-container>
                            <span *ngIf="getCountOfOtherTasks() > 0" class="counter">
                                {{ getCountOfOtherTasks() }}
                            </span>
                        </ng-container>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex-container content">
                    <app-group-checkboxes
                        name="selectedOtherTaskTemplates"
                        [entities]="otherTaskTemplates"
                        [previousEntities]="previouslySelectedOtherTaskTemplates"
                        [disablePreviousSelection]="true"
                        [(ngModel)]="selectedOtherTaskTemplates"
                    ></app-group-checkboxes>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</div>
