import { Component, Input } from '@angular/core';
import { RqRemittanceFrequencies } from '@app/constants/rq-remittance-frequencies';
import { TranslatableKey } from '@app/types/translatable.type';
import { BaseForm } from '@forms/base.form';
import { Company } from '@models/company/company.model';
import { isEmpty } from 'lodash-es';

interface RemittanceFrequencyListItem {
    key: string;
    value: TranslatableKey;
}

@Component({
    selector: 'app-company-payroll-rq-form',
    templateUrl: './payroll-rq.template.html',
    styleUrls: ['./payroll-rq.style.scss'],
})
export class PayrollRqForm extends BaseForm {
    @Input({ required: true }) company!: Company;
    @Input({ required: true }) authorizationsDocumentsHaveBeenSubmitted!: boolean;
    @Input() lastPaidRecurringPayrollEndDate?: Date | null;

    rqRemittanceFrequencies = RqRemittanceFrequencies;
    rqRemittanceFrequenciesList: RemittanceFrequencyListItem[] = Object.entries(RqRemittanceFrequencies).map(
        ([key, value]) => ({ key, value })
    );

    get required(): boolean {
        return Boolean(
            this.company.quebecEnterpriseNumber ||
                this.company.rqRegistration ||
                this.company.rqProgramCode ||
                this.company.rqReferenceNumber
        );
    }

    get onboarded(): boolean {
        if (this.doesntHaveStoredRQInfo()) {
            return false;
        }

        return Boolean(this.authorizationsDocumentsHaveBeenSubmitted || this.lastPaidRecurringPayrollEndDate);
    }

    /**
     * Whether Revenu Québec information is missing in the backend
     */
    private doesntHaveStoredRQInfo(): boolean {
        const company = this.company;

        for (const field of [
            'quebecEnterpriseNumber',
            'rqRegistration',
            'rqProgramCode',
            'rqReferenceNumber',
            'rqRemittanceFrequencyDisplay',
        ] as const) {
            if (isEmpty(company[field]) || company.isFieldDirty(field)) {
                return true;
            }
        }

        return false;
    }
}
