import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { AssetAssignment } from '@app/models/company/asset-assignment.model';

@Component({
    selector: 'app-assign-asset',
    templateUrl: './assign-asset.template.html',
    styleUrls: ['./assign-asset.style.scss'],
})
export class AssignAssetForm extends BaseForm {
    @Input() assetAssignment: AssetAssignment;
}
