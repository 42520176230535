<form #form="ngForm">
    <div class="responsive-grid" *ngIf="company">
        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.nameLabel' | translate }}</mat-label>
                <input
                    KYB-Revalidate
                    matInput
                    type="text"
                    name="name"
                    #name="ngModel"
                    [(ngModel)]="company.name"
                    required
                    placeholder="{{ 'company.company-info-form.namePlaceholder' | translate }}"
                />

                <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.legalNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="doingBusinessAsName"
                    #doingBusinessAsName="ngModel"
                    [required]="doingBusinessAsNameRequired"
                    [(ngModel)]="company.doingBusinessAsName"
                    placeholder="{{ 'company.company-info-form.legalNamePlaceholder' | translate }}"
                />
                <mat-error *ngIf="doingBusinessAsName.invalid">{{ doingBusinessAsName.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.dateFoundedLabel' | translate }}</mat-label>
                <input
                    KYB-Revalidate
                    required
                    name="incorporationDate"
                    #incorporationDate="ngModel"
                    [(ngModel)]="company.incorporationDate"
                    [matDatepicker]="incorporationDatePicker"
                    matInput
                />
                <mat-datepicker-toggle matSuffix [for]="incorporationDatePicker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #incorporationDatePicker></mat-datepicker>
                <mat-error *ngIf="incorporationDate.invalid">{{ incorporationDate.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.primaryPhoneLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="phonePrimary"
                    required
                    [(ngModel)]="company.phonePrimary"
                    #phonePrimary="ngModel"
                    placeholder="{{ 'company.company-info-form.primaryPhonePlaceholder' | translate }}"
                />
                <mat-error *ngIf="phonePrimary.invalid">{{ phonePrimary.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.faxLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="fax"
                    #fax="ngModel"
                    [(ngModel)]="company.fax"
                    placeholder="{{ 'company.company-info-form.faxPlaceholder' | translate }}"
                />
                <mat-error *ngIf="fax.invalid">{{ fax.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.websiteLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="website"
                    #website="ngModel"
                    [(ngModel)]="company.website"
                    placeholder="{{ 'company.company-info-form.websitePlaceholder' | translate }}"
                />
                <mat-error *ngIf="website.invalid">{{ website.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.industryLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="industry"
                    #industry="ngModel"
                    [(ngModel)]="company.industry"
                    placeholder="{{ 'company.company-info-form.industryPlaceholder' | translate }}"
                />
                <mat-error *ngIf="industry.invalid">{{ industry.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'company.company-info-form.notificationEmailLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="notification_email"
                    required
                    #email="ngModel"
                    [(ngModel)]="company.notification_email"
                    placeholder="{{ 'company.company-info-form.notificationEmailPlaceholder' | translate }}"
                />
                <mat-error *ngIf="email.invalid">{{ email.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </div>
</form>
