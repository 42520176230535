import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BulkSelectEmployeeDialogComponent } from '@app/components';
import { Employee } from '@app/models/employee/employee.model';
import { TaskSubtask } from '@app/models/tasks/task-subtask.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Task } from '@app/models/tasks/task.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-task-form',
    templateUrl: './task.template.html',
    styleUrls: ['./task.style.scss'],
})
export class TaskForm extends BaseForm implements OnInit {
    @ViewChild('selectEmployees', { static: true }) selectEmployees: BulkSelectEmployeeDialogComponent;

    @Input() employee: Employee;
    @Input() task: Task = new Task();

    toBeCompletedBy = 'subject';

    assignedEmployees: Employee[] = [];

    subTasks: TaskSubtask[] = [];
    removedSubTasks: TaskSubtask[] = [];

    ngOnInit(): void {
        // initialize sub tasks
        this.subTasks = this.task.subtasks;

        // initialize assignees
        this.assignedEmployees = this.task.assignees;

        // initialize assignee type & assignedEmployees property
        this.setInitialAssigneeType();
    }

    getEmptyTaskSubtask(): TaskSubtask {
        return new TaskSubtask();
    }

    onAddSubTasks(): void {
        this.subTasks.push(new TaskSubtask());
    }

    onRemoveSubTask(subTaskIndex: number): void {
        this.removedSubTasks.push(this.subTasks[subTaskIndex]);
        this.subTasks.splice(subTaskIndex, 1);
    }

    setSubTasks(subTasksList: TaskSubtask[]): void {
        this.subTasks = [...subTasksList];
    }

    onAssigneeChange(): void {
        this.task.assigneeType = this.toBeCompletedBy;

        if (this.task.assigneeType === Task.assigneeTypeSpecificEmployee) {
            return;
        }

        // reset assignees if toBeCompletedBy is not 'specific employee'
        this.attachAssignees([]);
        this.detachAssignees([]);
    }

    onAddOrRemoveEmployees(): void {
        this.selectEmployees.show<Employee[]>(this.assignedEmployees).then((selected: Employee[]) => {
            if (selected === null) {
                return;
            }

            this.assignedEmployees = selected;

            this.attachAssignees(this.assignedEmployees);
            this.detachAssignees(this.assignedEmployees);
        });
    }

    unassignEmployee(employeeToUnassign: Employee): void {
        this.assignedEmployees = this.assignedEmployees.filter(
            (employee: Employee) => employee.id !== employeeToUnassign.id
        );

        this.attachAssignees(this.assignedEmployees);
        this.detachAssignees(this.assignedEmployees);
    }

    private setInitialAssigneeType(): void {
        if (this.task.assigneeType) {
            this.toBeCompletedBy = this.task.assigneeType;
            return;
        }

        this.toBeCompletedBy = this.task.assignees.length ? TaskTemplate.assigeeTypeSpecificEmployee : 'subject';
        this.task.assigneeType = this.toBeCompletedBy;
    }

    private attachAssignees(selected: Employee[]): void {
        // Clear out assignees in task before attach
        this.task.clearAttach();

        const assigneesToAttach = selected
            .filter((selectedEmployee: Employee) => {
                return !this.task.assignees.includes(selectedEmployee);
            })
            .map((e: Employee) => e.id);

        this.task.attach('assignees', assigneesToAttach);
    }

    private detachAssignees(selected: Employee[]): void {
        const assigneesToDetach = this.task.assignees
            .filter((assignee: Employee) => {
                return !selected.includes(assignee);
            })
            .map((e: Employee) => e.id);

        this.task.detach('assignees', assigneesToDetach);
    }
}
