<form #form="ngForm">
    <ng-container *ngIf="company">
        <div class="responsive-grid">
            <div class="row">
                <mat-form-field [class.read-only-payroll-info-field]="onboarded">
                    <mat-label>{{ 'company.payroll-info-rq-form.quebecEnterpriseNumberLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        #quebecEnterpriseNumber="ngModel"
                        name="quebecEnterpriseNumber"
                        [(ngModel)]="company.quebecEnterpriseNumber"
                        [required]="!onboarded"
                        [readonly]="onboarded"
                        minlength="10"
                        maxlength="10"
                        placeholder="{{ 'company.payroll-info-rq-form.quebecEnterpriseNumberPlaceholder' | translate }}"
                    />
                    <mat-hint>{{ 'company.payroll-info-rq-form.tenDigitsOnly' | translate }}</mat-hint>
                    <mat-error *ngIf="quebecEnterpriseNumber.invalid">{{
                        quebecEnterpriseNumber.errors | formErrors
                    }}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field [class.read-only-payroll-info-field]="onboarded">
                    <mat-label>{{ 'company.payroll-info-rq-form.rqRegistrationLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        #rqRegistration="ngModel"
                        name="rqRegistration"
                        [(ngModel)]="company.rqRegistration"
                        [required]="!onboarded"
                        [readonly]="onboarded"
                        minlength="10"
                        maxlength="10"
                        placeholder="{{ 'company.payroll-info-rq-form.rqRegistrationPlaceholder' | translate }}"
                    />
                    <mat-hint *ngIf="!onboarded">{{
                        'company.payroll-info-rq-form.tenDigitsOnly' | translate
                    }}</mat-hint>
                    <mat-error *ngIf="rqRegistration.invalid">{{ rqRegistration.errors | formErrors }}</mat-error>
                </mat-form-field>

                <mat-form-field [class.read-only-payroll-info-field]="onboarded">
                    <mat-label>{{ 'company.payroll-info-rq-form.rqProgramCodeLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        #rqProgramCode="ngModel"
                        name="rqProgramCode"
                        [(ngModel)]="company.rqProgramCode"
                        [required]="!onboarded"
                        [readonly]="onboarded"
                        minlength="2"
                        maxlength="2"
                        pattern="[a-zA-Z]*"
                        placeholder="{{ 'company.payroll-info-rq-form.rqProgramCodePlaceholder' | translate }}"
                    />
                    <mat-hint *ngIf="!onboarded">{{
                        'company.payroll-info-rq-form.twoCharactersOnly' | translate
                    }}</mat-hint>
                    <mat-error *ngIf="rqProgramCode.invalid">{{ rqProgramCode.errors | formErrors }}</mat-error>
                </mat-form-field>

                <mat-form-field [class.read-only-payroll-info-field]="onboarded">
                    <mat-label>{{ 'company.payroll-info-rq-form.rqReferenceNumberLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        #rqReferenceNumber="ngModel"
                        name="rqReferenceNumber"
                        [(ngModel)]="company.rqReferenceNumber"
                        [required]="!onboarded"
                        [readonly]="onboarded"
                        minlength="4"
                        maxlength="4"
                        placeholder="{{ 'company.payroll-info-rq-form.rqReferenceNumberPlaceholder' | translate }}"
                    />
                    <mat-hint *ngIf="!onboarded">{{
                        'company.payroll-info-rq-form.fourDigitsOnly' | translate
                    }}</mat-hint>
                    <mat-error *ngIf="rqReferenceNumber.invalid">{{ rqReferenceNumber.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field>
                    <mat-label appRemoveAriaOwns>{{
                        'company.payroll-info-rq-form.rqRemittanceFrequencyLabel' | translate
                    }}</mat-label>
                    <mat-select
                        #rqRemittanceFrequency="ngModel"
                        [(ngModel)]="company.rqRemittanceFrequency"
                        name="rqRemittanceFrequency"
                        [required]="required"
                    >
                        <mat-option
                            *ngFor="let remittanceFrequencyListItem of rqRemittanceFrequenciesList"
                            [value]="remittanceFrequencyListItem.key"
                            >{{ remittanceFrequencyListItem.value | translate }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="rqRemittanceFrequency.invalid">{{
                        rqRemittanceFrequency.errors | formErrors
                    }}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
</form>
