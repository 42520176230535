<form autocomplete="nope" #form="ngForm" class="app-grid">
    <ng-container *ngIf="assetAssignment">
        <!-- Assign To -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.assignedTo' | translate }}</mat-label>
            <ui-employee-autocomplete
                name="assignedTo"
                [(ngModel)]="assetAssignment.employee"
                [placeholder]="'forms.assets.unassigned' | translate"
                #assignedTo="ngModel"
                [required]="true"
            ></ui-employee-autocomplete>
            <mat-error *ngIf="assignedTo.invalid">{{ assignedTo.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Date Assigned -->
        <mat-form-field *ngIf="assetAssignment.employee" class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.dateAssigned' | translate }}</mat-label>
            <input
                matInput
                name="dateAssigned"
                [matDatepicker]="picker"
                #dateAssigned="ngModel"
                required
                [(ngModel)]="assetAssignment.assignedOn"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </ng-container>
</form>
