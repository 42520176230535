import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { AssetCategory } from '@app/models/company/asset-category.model';

@Component({
    selector: 'app-asset-category-form',
    templateUrl: './asset-category.template.html',
    styleUrls: ['./asset-category.style.scss'],
})
export class AssetCategoryForm extends BaseForm {
    @Input() assetCategory: AssetCategory = new AssetCategory();
}
