<!-- Modals -->
<app-common-bulk-select-employee-dialog
    #selectEmployees
    headerText="forms.tasks-template.assignEmployees"
    maxItemsToSelect="5"
    [displayBannerBasedOnMaxItems]="true"
    bannerContent="forms.tasks-template.reachedMaxAssignees"
    [hideSelectPage]="true"
></app-common-bulk-select-employee-dialog>

<!-- Form -->
<form autocomplete="nope" #form="ngForm" class="app-grid">
    <ng-container *ngIf="taskTemplate">
        <!-- Name -->
        <mat-form-field class="columns-11 small-columns-12 margin-top-1">
            <mat-label>{{ 'forms.tasks-template.name' | appTranslate }}</mat-label>
            <input type="text" [(ngModel)]="taskTemplate.name" name="name" #name="ngModel" required matInput />

            <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Description -->
        <mat-form-field class="columns-11 small-columns-12 margin-top-1">
            <mat-label>{{ 'forms.tasks-template.description' | appTranslate }}</mat-label>
            <ui-rich-text
                name="description"
                #description="ngModel"
                [(ngModel)]="taskTemplate.description"
            ></ui-rich-text>
            <mat-error *ngIf="description.invalid">{{ description.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- File attachment field -->
        <mat-checkbox
            class="margin-top-1 margin-bottom-1"
            name="isAttachmentRequired"
            [(ngModel)]="taskTemplate.isAttachmentRequired"
            >{{ 'forms.tasks-template.fileAttachmentRequired' | appTranslate }}
        </mat-checkbox>

        <!-- Sub-tasks -->
        <ng-container *ngIf="!subTasks.length; else showSubTasks">
            <ui-button
                type="link"
                class="margin-bottom-1 margin-top-1 add-subtask-button"
                (click)="onAddSubTasks(); $event.preventDefault()"
            >
                <mat-icon class="margin-right-1" svgIcon="plus"></mat-icon
                >{{ 'forms.tasks-template.addSubtask' | appTranslate }}
            </ui-button>
        </ng-container>

        <ng-template #showSubTasks>
            <div class="app-grid margin-bottom-1 margin-top-2">
                <app-drag-and-drop-with-deletable-options
                    class="columns-12"
                    addButtonLabel="forms.tasks-template.addSubtask"
                    maxOptions="10"
                    optionAttributeName="description"
                    [emptyOption]="getEmptyTaskTemplateSubtask()"
                    [options]="subTasks"
                    (optionsChange)="setSubTasks($event)"
                    (optionRemoved)="onRemoveSubTask($event)"
                ></app-drag-and-drop-with-deletable-options>
            </div>
        </ng-template>

        <!-- Task type -->
        <div class="task-type margin-bottom-1">
            <mat-label class="margin-top-2">{{ 'forms.tasks-template.taskType' | appTranslate }} </mat-label>
            <mat-radio-group
                class="vertical margin-top-1 margin-bottom-2"
                name="taskTypeRadio"
                [(ngModel)]="taskTemplate.taskType"
                (ngModelChange)="onTaskTypeChange()"
                #taskTypeRadio="ngModel"
                required
            >
                <mat-radio-button [value]="taskTypes.onboarding">{{
                    'forms.tasks-template.onboarding' | appTranslate
                }}</mat-radio-button>
                <p class="margin-left-3">
                    {{ 'forms.tasks-template.forNewHiresTaskDueDatesAreDeterminedByNewHiresStartDate' | appTranslate }}
                </p>

                <mat-radio-button [value]="taskTypes.offboarding">{{
                    'forms.tasks-template.offboarding' | appTranslate
                }}</mat-radio-button>
                <p class="margin-left-3">
                    {{
                        'forms.tasks-template.forOffboardingEmployeesTaskDueDatesAreDeterminedByOffboardingEmp'
                            | appTranslate
                    }}
                </p>

                <mat-radio-button [value]="taskTypes.general"
                    >{{ 'forms.tasks-template.general' | appTranslate }}
                    <span class="new-label">{{ 'forms.tasks-template.new' | appTranslate }}</span>
                </mat-radio-button>
                <p class="margin-left-3">
                    {{
                        'forms.tasks-template.forAssigningToOneOrMoreExistingEmployeesTaskDueDatesAreDetermine'
                            | appTranslate
                    }}
                </p>
            </mat-radio-group>
        </div>

        <hr />

        <!-- Applicable to (requiredFor) -->
        <div
            *ngIf="taskTemplate.taskType === taskTypes.offboarding || taskTemplate.taskType === taskTypes.onboarding"
            class="required-for"
        >
            <mat-label class="columns-2 margin-top-1"
                >{{ 'forms.tasks-template.applicableTo' | appTranslate }}
            </mat-label>
            <ui-info
                class="columns-1 margin-left-1"
                text="{{
                    'forms.tasks-template.thisWillDetermineWhetherTheTaskIsRelevantToAllEmployee' | appTranslate
                }}"
            ></ui-info>

            <mat-radio-group
                class="required-for-radio vertical margin-top-1"
                name="requiredFor"
                [(ngModel)]="requiredForAll"
                #requiredFor="ngModel"
                required
            >
                <mat-radio-button [value]="true">{{
                    'forms.tasks-template.allEmployees' | appTranslate
                }}</mat-radio-button>
                <mat-radio-button [value]="false">{{
                    'forms.tasks-template.specificEmployees' | appTranslate
                }}</mat-radio-button>
            </mat-radio-group>

            <mat-error *ngIf="requiredFor.invalid">{{ requiredFor.errors | formErrors }}</mat-error>

            <div *ngIf="requiredForAll === false" class="margin-top-1">
                <app-employee-criteria-selection
                    #employeeCriteriaSelection
                    [employeeFilter]="taskTemplate.employeeFilter"
                ></app-employee-criteria-selection>
            </div>
        </div>

        <!-- To be completed by -->
        <div class="completed-by margin-top-1">
            <mat-label class="columns-2 margin-top-1"
                >{{ 'forms.tasks-template.toBeCompletedBy' | appTranslate }}
            </mat-label>
            <ui-info
                class="columns-1 margin-left-1"
                text="{{ 'forms.tasks-template.thisWillDetermineWhetherTheTaskIsToBeCompletedByTheN' | appTranslate }}"
            ></ui-info>

            <mat-radio-group
                class="completed-by-radio vertical margin-top-1"
                name="completedByRadio"
                [(ngModel)]="toBeCompletedBy"
                (ngModelChange)="onAssigneeChange()"
                #completedByRadio="ngModel"
                required
            >
                <!-- onboarding task type -->
                <ng-container *ngIf="taskTemplate.taskType === taskTypes.onboarding">
                    <mat-radio-button [value]="'subject'">{{
                        'forms.tasks-template.newHire' | appTranslate
                    }}</mat-radio-button>
                </ng-container>

                <!-- offboarding task type -->
                <ng-container *ngIf="taskTemplate.taskType === taskTypes.offboarding">
                    <mat-radio-button [value]="'subject'">{{
                        'forms.tasks-template.offboardingEmployee' | appTranslate
                    }}</mat-radio-button>
                </ng-container>

                <!-- general task type -->
                <ng-container *ngIf="taskTemplate.taskType === taskTypes.general">
                    <mat-radio-button [value]="'subject'">{{
                        'forms.tasks-template.employeeSelf' | appTranslate
                    }}</mat-radio-button>
                </ng-container>

                <mat-radio-button [value]="'manager'">{{
                    'forms.tasks-template.directManager' | appTranslate
                }}</mat-radio-button>
                <mat-radio-button [value]="'specific employee'">{{
                    'forms.tasks-template.specificEmployees' | appTranslate
                }}</mat-radio-button>
            </mat-radio-group>

            <!-- Specific employees -->
            <ng-container *ngIf="toBeCompletedBy === 'specific employee'">
                <!-- If there are assignees (up to max 5 only), then show Assignees Table -->
                <ng-container *ngIf="assignedEmployees.length; else noAssignees">
                    <div class="assignees-table">
                        <h3 class="ui header title">{{ 'forms.tasks-template.employeesSelected' | appTranslate }}</h3>

                        <div *ngFor="let assignedEmployee of assignedEmployees" class="row padding-y-1">
                            <div class="app-grid">
                                <!-- Employee avatar & name-->
                                <app-avatar
                                    class="columns-9"
                                    size="font-fixed"
                                    [employee]="assignedEmployee"
                                    [name]="true"
                                >
                                </app-avatar>

                                <!-- Remove button -->
                                <ui-button
                                    type="ghost"
                                    class="unassign-employee-button columns-3 justify-end"
                                    (click)="unassignEmployee(assignedEmployee)"
                                    ><mat-icon class="margin-right-1" svgIcon="close"></mat-icon
                                    >{{ 'forms.tasks-template.remove' | appTranslate }}</ui-button
                                >
                            </div>
                        </div>
                    </div>

                    <ui-button type="outline" class="margin-top-2 margin-bottom-2" (click)="onAddOrRemoveEmployees()">{{
                        'forms.tasks-template.addOrRemoveEmployees' | appTranslate
                    }}</ui-button>
                </ng-container>

                <!-- If there are no assignees -->
                <ng-template #noAssignees>
                    <ui-button type="outline" class="margin-top-1 margin-bottom-2" (click)="onAddOrRemoveEmployees()">{{
                        'forms.tasks-template.addEmployees' | appTranslate
                    }}</ui-button>
                </ng-template>
            </ng-container>
        </div>

        <!-- Due Date -->
        <div class="margin-top-1">
            <mat-label>{{ 'forms.tasks-template.dueDate' | appTranslate }}</mat-label>
            <mat-radio-group
                class="due-date-radio vertical margin-top-1"
                name="dueDateRadio"
                [(ngModel)]="taskTemplate.dueDateType"
                (ngModelChange)="onDueDateTypeChange()"
                #dueDateRadio="ngModel"
            >
                <mat-radio-button [value]="'none'">{{ 'forms.tasks-template.none' | appTranslate }}</mat-radio-button>

                <!-- onboarding task type -->
                <ng-container *ngIf="taskTemplate.taskType === taskTypes.onboarding">
                    <mat-radio-button [value]="'on hired date'">{{
                        'forms.tasks-template.onStartDate' | appTranslate
                    }}</mat-radio-button>
                </ng-container>

                <!-- offboarding task type -->
                <ng-container *ngIf="taskTemplate.taskType === taskTypes.offboarding">
                    <mat-radio-button [value]="'on last day'">{{
                        'forms.tasks-template.onLastDayOfWork' | appTranslate
                    }}</mat-radio-button>
                </ng-container>

                <mat-radio-button [value]="'custom'">{{
                    'forms.tasks-template.custom' | appTranslate
                }}</mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- Custom Due date -->
        <ng-container *ngIf="taskTemplate.isCustomDueDateType()">
            <div class="flex-container content">
                <!-- Due offset -->
                <mat-form-field class="due-offset">
                    <input
                        type="number"
                        min="0"
                        [(ngModel)]="taskTemplate.dueOffset"
                        name="dueOffset"
                        #name="ngModel"
                        required
                        matInput
                    />
                </mat-form-field>

                <!-- Due type -->
                <mat-form-field class="due-type">
                    <mat-select
                        name="dueType"
                        [(ngModel)]="taskTemplate.dueType"
                        placeholder="{{ 'forms.tasks-template.days' | appTranslate }}"
                        required
                    >
                        <mat-option value="day">{{ 'forms.tasks-template.days' | appTranslate }}</mat-option>
                        <mat-option value="month">{{ 'forms.tasks-template.months' | appTranslate }}</mat-option>
                        <mat-option value="year">{{ 'forms.tasks-template.years' | appTranslate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Due relation (By default, due relation dropdown is disabled for general tasks) -->
                <ng-container *ngIf="taskTemplate.taskType !== taskTypes.general">
                    <ng-container *ngIf="!isAfterDueRelationDisabled()">
                        <mat-form-field class="due-relation">
                            <mat-select
                                name="dueRelation"
                                [(ngModel)]="taskTemplate.dueRelation"
                                placeholder="{{ 'forms.tasks-template.before' | appTranslate }}"
                                required
                            >
                                <mat-option
                                    *ngFor="let dueRelation of taskTemplate.getDueRelations()"
                                    [value]="dueRelation"
                                >
                                    {{ dueRelation | titlecase }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="isAfterDueRelationDisabled()">
                        <mat-label>before &nbsp;</mat-label>
                    </ng-container>
                </ng-container>

                <!-- Due date label -->
                <mat-label *ngIf="taskTemplate.taskType === taskTypes.onboarding">{{
                    'forms.tasks-template.startDate' | appTranslate
                }}</mat-label>
                <mat-label *ngIf="taskTemplate.taskType === taskTypes.offboarding">{{
                    'forms.tasks-template.lastDayOfWork' | appTranslate
                }}</mat-label>
                <mat-label *ngIf="taskTemplate.taskType === taskTypes.general">{{
                    'forms.tasks-template.afterAssigned' | appTranslate
                }}</mat-label>
            </div>
        </ng-container>
    </ng-container>
</form>
